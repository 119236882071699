<script>
import { HorizontalBar } from "vue-chartjs";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";

export default {
  extends: HorizontalBar,
  props: ["chartTitle", "dataSource", "percentage", "totalOveride"],
  data() {
    return {
      chartData: []
    };
  },
  watch: {
    dataSource(val, oldval) {
      this.chartData = val;
      this.renderTheChart();
    }
  },
  computed: {
    percentageData() {
      let percentageData = [],
        numberData = this.chartData.map(element => {
          return element.value;
        });

      let totalNumber =
        this.totalOveride == undefined
          ? numberData.reduce((total, number) => {
              return total + number;
            })
          : this.totalOveride;


      percentageData = numberData.map(value => {
        return (value / totalNumber) * 100;
      });


      return percentageData;
    },
    valueData() {
      let numberData = this.chartData.map(element => {
        return element.value;
      });

      return numberData;
    },
    title() {
      return this.chartTitle == undefined ? "" : this.chartTitle;
    },
    labels() {
      let labels = this.chartData.map(element => {
        return element.name;
      });
      return labels;
    },
    backgroundColors() {
      let colors = this.chartData.map(element => {
        return element.color;
      });
      return colors;
    },
    displayData() {
      if (this.percentage != undefined && this.percentage == true) {
        return this.percentageData;
      } else {
        return this.valueData;
      }
    }
  },
  mounted() {
    if (typeof this.dataSource === "object") {
      this.chartData = this.dataSource;
      this.renderTheChart();
    } else {
      axios.get(this.dataSource).then(response => {
        this.chartData = response.data.chartData;

        this.renderTheChart();
      });
    }
  },
  methods: {
    renderTheChart() {
      if (this.$data._chart) {
        this.$data._chart.destroy();
      }

      this.renderChart(
        {
          labels: this.labels,
          datasets: [
            {
              backgroundColor: this.backgroundColors,
              data: this.displayData
            }
          ]
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                gridLines: {
                  color: "#e6ecf5"
                }
              }
            ],
            yAxes: [
              {
                categorySpacing: 0,
                categoryPercentage: 1,
                barPercentage: 0.7,
                ticks: {
                  beginAtZero: true
                },
                gridLines: {
                  color: "#e6ecf5",
                  lineWidth: 0.4
                }
              }
            ]
          },
          legend: {
            display: false
          },
          title: {
            display: this.title.length > 0 ? true : false,
            text: this.title,
            position: "top"
          },
          tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                let allData = data.datasets[tooltipItem.datasetIndex].data,
                  tooltipLabel = data.labels[tooltipItem.index],
                  tooltipData = allData[tooltipItem.index];
                if (this.percentage != undefined && this.percentage == true) {
                  return tooltipLabel + ": " + Math.round(tooltipData) + "%";
                } else {
                  return tooltipLabel + ": " + Math.round(tooltipData);
                }
              }
            }
          },
          plugins: {
            datalabels: {
              color: "#656565",
              font: {
                weight: "bold"
              },
              anchor: "end",
              align: "start",
              offset: 5,
              formatter: (value, context) => {
                if (value == 0) {
                  return "";
                } else if (
                  this.percentage != undefined &&
                  this.percentage == true
                ) {
                  return Math.round(value) + "%";
                } else {
                  return Math.round(value);
                }
              }
            }
          }
        }
      );
    }
  }
};
</script>

<style scoped>
</style>
